

import axios from 'axios'
import { mapState } from 'vuex'

export default {
  auth: 'guest',

  layout: 'guest',

  head: {
    title: 'Login'
  },

  computed: {
    ...mapState({
      loading: state => state.loading
    })
  },

  asyncData () {
    return {
      formData: {
        email: '',
        password: ''
      }
    }
  },

  created () {
    this.$router.push('/')
    this.$store.commit('SET_LOADING', true, { root: true })

    if (this.$auth.loggedIn) {
      this.$router.push('/')
      this.$snackbar({ icon: 'mdi-checkbox-marked-circle.green', text: 'Welcome back!' })
    }

    this.$store.commit('SET_LOADING', false, { root: true })
  },

  methods: {
    async login () {
      this.$store.commit('SET_LOADING', true)
      try {
        await this.$auth.loginWith('password_grant', {
          data: {
            username: this.formData.email,
            password: this.formData.password,
            isAzureLogin: false
          }
        })
        this.$snackbar({ icon: 'mdi-checkbox-marked-circle.green', text: 'You are logged in' })
      } catch (e) {
        if (e.response.status === 500) this.$snackbar({ icon: 'mdi-close-circle.red', text: 'Please enter your credentials' })
        else if (e.response.status === 403) this.$snackbar({ icon: 'mdi-close-circle.red', text: e.response.data })
      }
    },

    msalLogin () {
      this.$AuthService.login('/azure')
    }
  }
}
